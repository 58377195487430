import { PushNotificationData } from "./components/networking/firestore/models"

export interface ReduxAction<T, V> {
    type: T,
    props: V
}

export interface AppFrameState {
    statusSnackMessage?: {
        variant: string,
        value: string
    },
    updateAvailable?: boolean,
    bgSnackMessage?: string,
    bgDialogMessage?: string,
    confirmDialogMessage?: {
        value: string,
        title?: string,
        onClickConfirm: () => void,
        onClickCancel: () => void
    }
}

export enum AppFrameAction {
    SET_STATUS_SNACKBAR = "SET_STATUS_SNACKBAR",
    SET_UPDATE_SNACKBAR = "SET_UPDATE_SNACKBAR",
    SET_BGPROC_SNACKBAR = "SET_BGPROC_SNACKBAR",
    SET_CONFIRM_DIALOG = "SET_CONFIRM_DIALOG",
    SET_BGPROC_DIALOG = "SET_BGPROC_DIALOG",
    HIDE_STATUS_SNACKBAR = "HIDE_STATUS_SNACKBAR",
    HIDE_BGPROC_SNACKBAR = "HIDE_BGPROC_SNACKBAR",
    HIDE_BGPROC_DIALOG = "HIDE_BGPROC_DIALOG",
}

export interface ServiceWorkerState {
    checking?: boolean,
    initialized?: boolean,
    updated?: boolean,
    registration?: any
}

export enum ServiceWorkerAction {
    SW_REGISTER = "SW_REGISTER",                // initialize serviceworker creation
    SW_INIT = "SW_INIT",                        // serviceworker created
    SW_UPDATE_TIMEOUT = "SW_UPDATE_TIMEOUT",    // serviceworker didn't receive onupdate event after fixed time
    SW_UPDATE = "SW_UPDATE"                     // update available for service worker
}

export enum SessionAction {

    INIT_SESSION = "INIT_SESSION",
    END_SESSION = "END_SESSION",

    SET_PLACES = "SET_PLACES",
    SET_PROFILE = "SET_PROFILE",
    CLEAR_PLACES = "CLEAR_PLACES",

    SET_ACTIVE_PERMISSIONS = "SET_ACTIVE_PERMISSIONS",
    CLEAR_ACTIVE_PERMISSIONS = "CLEAR_ACTIVE_PERMISSIONS",
    SET_ACTIVE_PLACE = "SET_ACTIVE_PLACE"
}

export interface SessionState {
    // initialized on login
    accountType?: string,
    places?: Array<Record<string, any>>,
    drawerMenu?: Array<Record<string, any>>,
    firstName?: string,
    lastName?: string,
    email?: string

    // these fields are set after selecting a location to manage
    placeId?: string,
    teamId?: string,
    permissions?: string,

    //misc data?
    [key: string]: any,
}

export enum CredentialAction {
    SET_CREDENTIALS = "SET_CREDENTIALS",
    CLEAR_CREDENTIALS = "CLEAR_CREDENTIALS",
    SET_EMAIL = "SET_EMAIL"

}

export interface CredentialState {
    username?: string | null,
    password?: string | null,
    email?: string | null
}


export enum AirportAction {
    SET_AIRPORT = "SET_AIRPORT",
    CLEAR_AIRPORT = "CLEAR_AIRPORT"
}

export interface AirportState {
    airportId?: string,
    iataCode?: string,
    name?: string,
    iso4217?: string,
    country?: string,
    countryCode?: string,
    timezone?: string,
    latitude?: number,
    longitude?: number
}

export interface PushNotificationStateTransition {
    enabled?: boolean,
    maxQueueLength?: number,
    notificationId?: string,
    notification?: PushNotificationData
}

export interface PushNotificationState {
    enabled?: boolean
    maxQueueLength?: number
    notifications?: Array<PushNotificationData>
}


export enum PushNotificationPermActions {
    SET_ENABLED = "SET_ENABLED",
}

export enum PushNotificationAction {
    PUSH_NOTIS_DATA = "PUSH_NOTIS_DATA",
    POP_NOTIS_DATA = "POP_NOTIS_DATA",
    CLEAR_NOTIS_DATA = "CLEAR_NOTIS_DATA",
    SET_MAX_QUEUE_LENGTH = "SET_MAX_QUEUE_LENGTH"
}