import { SessionAction, SessionState, ReduxAction } from "../constants"

export default (prevState: SessionState = {}, action: ReduxAction<SessionAction, SessionState>): SessionState => {
    switch (action.type) {
        case SessionAction.INIT_SESSION: {
            const { accountType, drawerMenu } = action.props
            return {
                ...prevState,
                accountType, 
                drawerMenu
            }
        }
        case SessionAction.END_SESSION: {
            return {
                ...prevState,
                accountType: null,
                places: [],
                drawerMenu: [],
                firstName: null,
                lastName: null,
                email: null,
                placeId: null,
                teamId: null,
                permissions: null                
            }
        }
        case SessionAction.SET_PLACES: {
            const { places } = action.props
            return {
                ...prevState,
                places
            }
        }
        case SessionAction.SET_PROFILE: {
            const { firstName, lastName, email } = action.props
            return {
                ...prevState,
                firstName, lastName, email
            }
        }
        case SessionAction.CLEAR_PLACES: {
            return {
                ...prevState,
                places: []
            }
        }
        case SessionAction.SET_ACTIVE_PERMISSIONS: {
            const { placeId, teamId, permissions } = action.props
            return {
                ...prevState,
                placeId,
                teamId,
                permissions
            }
        }
        case SessionAction.CLEAR_ACTIVE_PERMISSIONS: {
            return {
                ...prevState,
                placeId: null,
                teamId: null,
                permissions: null
            }
        }
        case SessionAction.SET_ACTIVE_PLACE: {
            const { placeId } = action.props
            return {
                ...prevState,
                placeId
            }
        }
        default:
            return prevState
    }
}
