import { AppFrameAction, AppFrameState, ReduxAction } from "../constants"

export function showStatusSnackbar(variant: string, value: string): ReduxAction<AppFrameAction, AppFrameState> {
    const action: ReduxAction<AppFrameAction, AppFrameState> = {
        type: AppFrameAction.SET_STATUS_SNACKBAR,
        props: {
            statusSnackMessage: { variant, value }
        }
    }
    return action
}

export function clearStatusSnackbar(): ReduxAction<AppFrameAction, AppFrameState> {
    const action: ReduxAction<AppFrameAction, AppFrameState> = {
        type: AppFrameAction.HIDE_STATUS_SNACKBAR,
        props: {}
    }
    return action
}

export function showUpdateSnackbar(): ReduxAction<AppFrameAction, AppFrameState> {
    const action: ReduxAction<AppFrameAction, AppFrameState> = {
        type: AppFrameAction.SET_UPDATE_SNACKBAR,
        props: {
            updateAvailable: true
        }
    }
    return action
}

export function showConfirmDialog(message: string, onClickCancel: () => void, onClickConfirm: () => void,
    title="Are you sure?"): ReduxAction<AppFrameAction, AppFrameState> {

    const action: ReduxAction<AppFrameAction, AppFrameState> = {
        type: AppFrameAction.SET_CONFIRM_DIALOG,
        props: {
            confirmDialogMessage: {
                value: message,
                title,
                onClickCancel,
                onClickConfirm
            }
        }
    }
    return action
}

export function showBGProcessSnackbar(message="Processing..."): ReduxAction<AppFrameAction, AppFrameState> {
    const action: ReduxAction<AppFrameAction, AppFrameState> = {
        type: AppFrameAction.SET_BGPROC_SNACKBAR,
        props: {
            bgSnackMessage: message
        }
    }
    return action
}

export function showBGProcessDialog(message= "Loading..."): ReduxAction<AppFrameAction, AppFrameState> {
    const action: ReduxAction<AppFrameAction, AppFrameState> = {
        type: AppFrameAction.SET_BGPROC_DIALOG,
        props: {
            bgDialogMessage: message
        }
    }
    return action
}

export function hideBGProcessSnackbar(): ReduxAction<AppFrameAction, AppFrameState> {
    const action: ReduxAction<AppFrameAction, AppFrameState> = {
        type: AppFrameAction.HIDE_BGPROC_SNACKBAR,
        props: {
            bgSnackMessage: ""
        }
    }
    return action
}

export function hideBGProcessDialog(): ReduxAction<AppFrameAction, AppFrameState> {
    const action: ReduxAction<AppFrameAction, AppFrameState> = {
        type: AppFrameAction.HIDE_BGPROC_DIALOG,
        props: {
            bgDialogMessage: ""
        }
    }
    return action
}