import "dotenv/config"
import "react-notifications-component/dist/theme.css"
import "animate.css/animate.min.css"
import { cleanEnv, num, str, url } from "envalid"
import { ReactNotifications } from "react-notifications-component"

// make sure all required env vars are defined properly
cleanEnv(process.env, {
    REACT_APP_ADMIN_USER: str(),
    REACT_APP_ADMIN_SECRET: str(),
    REACT_APP_API_HOST: url(),
    REACT_APP_GCP_PLATFORM_HOST: url(),
    REACT_APP_GCP_DASHBOARD_HOST: url(),
    REACT_APP_FIREBASE_PLATFORM_KEY: str(),
    REACT_APP_FIREBASE_PLATFORM_PROJECT: str(),
    REACT_APP_FIREBASE_KEY: str(),
    REACT_APP_FIREBASE_PROJECT: str(),
    REACT_APP_VERSION: str(),
    REACT_APP_INTERCOM_APPID: str(),
    REACT_APP_RECAPTCHA_SITEKEY: str(),
    REACT_APP_MAPBOX_KEY: str(),
    REACT_APP_ROLLBAR_KEY: str(),
    REACT_APP_DEVELOPMENT: num(),
    REACT_APP_VAPID_KEY: str()
})
import { airportAuth, platformMessaging } from "./firebase"

import React, { Suspense, lazy } from 'react';
import ReactDOM, { render } from "react-dom"
import { Provider } from "react-redux"
import { IntlProvider } from "react-intl"
import { Route, Switch, Redirect } from "react-router"
import { createStore } from "redux"
import { BrowserRouter } from "react-router-dom"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { v4 as uuidv4 } from "uuid"
import {
    onAuthStateChanged as firebaseOnAuthStateChanged
} from "firebase/auth"
import {
    MessagePayload as FirebaseMessagePayload,
    onMessage as firebaseOnMessage
} from "firebase/messaging"

import { MaterialFoxTheme } from "./components/shared/themes/fetchyfox"
import reportWebVitals from './reportWebVitals';
import {
    serviceWorkerSuccess,
    serviceWorkerHasUpdate,
    serviceWorkerInitialize,
    serviceWorkerUpdateTimeout,
    setCredentials,
    // pushNotificationData
} from "./actions"
import { register as registerServiceWorker } from "./serviceWorkerRegistration"
import reducer from "./reducers"
import { SuspenseScreen } from "./components/views/Suspense"
import { AccountType } from "./components/shared/utils"
// import { PushNotificationData } from "./components/networking/firestore/models"

const AppFrameContainer = lazy(() => import("./components/AppFrame"))
const DashboardPageFrameContainer = lazy(() => import("./components/DashboardPageFrame"))

const Home = lazy(() => import("./components/Home"))
const Dashboard = lazy(() => import("./components/Dashboard"))
const RequestPasswordReset = lazy(() => import("./components/RequestPasswordReset"))
const PasswordReset = lazy(() => import("./components/PasswordReset"))
const VerifyContactContainer = lazy(() => import("./components/fox/VerifyContact"))
const BasicInfoContainer = lazy(() => import("./components/fox/BasicInfo"))
const PrepaidCard = lazy(() => import("./components/fox/PrepaidCard"))
const StripeInfoContainer = lazy(() => import("./components/StripeInfo"))

const OverviewContainer = lazy(() => import("./components/merchant/Overview"))
const MerchantBasicInfo = lazy(() => import("./components/merchant/BasicInfo"))
const SellableLibraryContainer = lazy(() => import("./components/merchant/SellableLibrary"))
const ProductLibraryContainer = lazy(() => import("./components/merchant/ProductLibrary"))
const CouponLibraryContainer = lazy(() => import("./components/merchant/CouponLibrary"))
const MerchantSchedulerContainer = lazy(() => import("./components/merchant/Scheduler"))
const PoiSettingsContainer = lazy(() => import("./components/merchant/PoiSettings"))
const ActiveOrdersContainer = lazy(() => import("./components/merchant/ActiveOrders"))
const MerchantShoppingTrendsContainer = lazy(() => import("./components/merchant/ShoppingTrends"))
const MerchantListingOptimizationContainer = lazy(() => import("./components/merchant/MarginOptimization"))
const MerchantRefundRequestContainer = lazy(() => import("./components/merchant/RefundRequests"))
const PastOrdersContainer = lazy(() => import("./components/merchant/PastOrders"))
const MerchantOatChecksContainer = lazy(() => import("./components/merchant/oat/Checks"))
const MerchantOatQRCodesContainer = lazy(() => import("./components/merchant/oat/QRCodes"))
const MerchantOatTablesContainer = lazy(() => import("./components/merchant/oat/Seating"))
const EmployeesContainer = lazy(() => import("./components/merchant/oat/Employees"))
const TipLogContainer = lazy(() => import("./components/merchant/oat/TipLog"))

const FoxPerformanceContainer = lazy(() => import("./components/airport/FoxPerformance"))
const Applicants = lazy(() => import("./components/airport/Applicants"))
const ActivePaymentCardsContainer = lazy(() => import("./components/airport/ActivePaymentCards"))
const ActiveDeliveriesContainer = lazy(() => import("./components/airport/ActiveDeliveries"))
const OrderLookupContainer = lazy(() => import("./components/airport/OrderLookup"))
const AirportListingsContainer = lazy(() => import("./components/airport/Listings"))
const MenuContainer = lazy(() => import("./components/airport/Menu"))
const ReviewsContainer = lazy(() => import("./components/airport/Reviews"))
const ShoppingTrendsContainer = lazy(() => import("./components/airport/ShoppingTrends"))
const SurveyManagerContainer = lazy(() => import("./components/airport/Surveys"))
const DemographicsContainer = lazy(() => import("./components/airport/Demographics"))

const OnboardingContainer = lazy(() => import("./components/Onboarding"))
const TeamContainer = lazy(() => import("./components/Team"))


const store = createStore(reducer)

// listener for push notifications from firebase
firebaseOnMessage(platformMessaging, (payload: FirebaseMessagePayload) => {
    console.debug('Received foreground message: ', payload);

    // TODO: enable when ready to implement this feature
    // const timestampUtc = payload.data ? payload.data["google.c.a.ts"] : "0"
    // const notification: PushNotificationData = {
    //     id: payload.messageId,
    //     title: payload.notification?.title || "",
    //     message: payload.notification?.body || "",
    //     timestampUtc: moment.unix(timestampUtc).format(),
    //     receivedUtc: moment().format(),
    //     visible: true,
    //     variant: "foreground"
    // }
    // store.dispatch(pushNotificationData(notification))
})


// listen for login status of airport
firebaseOnAuthStateChanged(airportAuth, (user) => {
    if (user) {
        user.getIdToken().then((idToken) => {
            store.dispatch(setCredentials(user.email, idToken))
        })
    }
    else {
        store.dispatch(setCredentials(null, null))
    }
})

function renderAuthorized(routerProps: any, component: React.ReactElement) {
    const state = store.getState()
    if (state.credentials.username !== undefined && state.credentials.username !== null) {
        return <AppFrameContainer location={routerProps.location} component={component} key={uuidv4()} />
    }
    else {
        return <Redirect to="/" />
    }
}

const App = () => {
    return (
        <IntlProvider locale={navigator.language}>
            <Provider store={store}>
                <MuiThemeProvider theme={MaterialFoxTheme}>
                    <BrowserRouter>
                        <Suspense fallback={<SuspenseScreen />}>
                            <Switch>
                                <Route exact path="/dashboard" render={(props: any) => renderAuthorized(props, <Dashboard {...props} />)} />
                                <Route path="/fox/mobile" render={(props: any) => renderAuthorized(props, <VerifyContactContainer {...props} />)} />
                                <Route path="/fox/basic" render={(props: any) => renderAuthorized(props, <BasicInfoContainer {...props} />)} />
                                <Route path="/fox/prepaid" render={(props: any) => renderAuthorized(props, <PrepaidCard {...props} />)} />
                                <Route path="/fox/platform" render={(props: any) => renderAuthorized(props, <StripeInfoContainer {...props} accountType={AccountType.FOX_ACCOUNT} />)} />

                                <Route path="/merchant/overview" render={(props: any) => renderAuthorized(props, <OverviewContainer {...props} />)} />
                                <Route path="/merchant/basic" render={(props: any) => renderAuthorized(props, <MerchantBasicInfo {...props} />)} />
                                <Route path="/merchant/platform" render={(props: any) => renderAuthorized(props, <StripeInfoContainer {...props} accountType={AccountType.MERCHANT_ACCOUNT} />)} />
                                <Route exact path="/library" render={(props: any) => renderAuthorized(props, <ProductLibraryContainer {...props} />)} />
                                <Route path="/listings" render={(props: any) => renderAuthorized(props, <SellableLibraryContainer {...props} />)} />
                                <Route path="/merchant/shopping" render={(props: any) => renderAuthorized(props, <MerchantShoppingTrendsContainer {...props} />)} />

                                <Route path="/merchant/ai/listings" render={(props: any) => renderAuthorized(props, <MerchantListingOptimizationContainer {...props} />)} />


                                <Route path="/merchant/coupons" render={(props: any) => renderAuthorized(props, <CouponLibraryContainer {...props} />)} />
                                <Route path="/merchant/team" render={(props: any) => renderAuthorized(props, <TeamContainer {...props} variant={AccountType.MERCHANT_ACCOUNT} />)} />

                                <Route path="/merchant/schedule" render={(props: any) => renderAuthorized(props, <MerchantSchedulerContainer {...props} />)} />
                                <Route path="/merchant/settings" render={(props: any) => renderAuthorized(props, <PoiSettingsContainer {...props} />)} />
                                <Route path="/merchant/orders" render={(props: any) => renderAuthorized(props, <ActiveOrdersContainer {...props} />)} />

                                {/* this is the preffered method of rendering pages going forward 2022-11-11. it will allow the side menu to maintain its own state supporting future features. it
                                also removes a ton of copy-paste code from the components into the new container. */}
                                <Route path="/merchant/refunds" render={(props: any) => {
                                    const component = <DashboardPageFrameContainer {...props} component={<MerchantRefundRequestContainer />} />
                                    return renderAuthorized(props, component)
                                }} />
                                <Route path="/merchant/checks" render={(props: any) => {
                                    const component = <DashboardPageFrameContainer {...props} component={<PastOrdersContainer />} />
                                    return renderAuthorized(props, component)
                                }} />
                                <Route path="/merchant/oat/checks" render={(props: any) => {
                                    const component = <DashboardPageFrameContainer {...props} component={<MerchantOatChecksContainer />} />
                                    return renderAuthorized(props, component)
                                }} />
                                <Route path="/merchant/oat/seats" render={(props: any) => {
                                    const component = <DashboardPageFrameContainer {...props} component={<MerchantOatTablesContainer />} />
                                    return renderAuthorized(props, component)
                                }} />
                                <Route path="/merchant/oat/qrcodes" render={(props: any) => {
                                    const component = <DashboardPageFrameContainer {...props} component={<MerchantOatQRCodesContainer />} />
                                    return renderAuthorized(props, component)
                                }} />
                                <Route path="/merchant/oat/employees" render={(props: any) => {
                                    const component = <DashboardPageFrameContainer {...props} component={<EmployeesContainer />} />
                                    return renderAuthorized(props, component)
                                }} />                                
                                <Route path="/merchant/oat/tiplog" render={(props: any) => {
                                    const component = <DashboardPageFrameContainer {...props} component={<TipLogContainer />} />
                                    return renderAuthorized(props, component)
                                }} />
                                {/* --- END  */}


                                <Route path="/applicants/:applicantType" render={(props: any) => renderAuthorized(props, <Applicants {...props} />)} />
                                <Route path="/activedeliveries" render={(props: any) => renderAuthorized(props, <ActiveDeliveriesContainer {...props} />)} />
                                <Route path="/activecards" render={(props: any) => renderAuthorized(props, <ActivePaymentCardsContainer {...props} />)} />
                                <Route path="/lookup" render={(props: any) => renderAuthorized(props, <OrderLookupContainer {...props} />)} />

                                <Route path="/airport/shopping" render={(props: any) => renderAuthorized(props, <ShoppingTrendsContainer {...props} />)} />
                                <Route path="/airport/surveys" render={(props: any) => renderAuthorized(props, <SurveyManagerContainer {...props} />)} />
                                <Route path="/airport/listings" render={(props: any) => renderAuthorized(props, <AirportListingsContainer {...props} />)} />
                                <Route path="/airport/listing/:placeId" render={(props: any) => renderAuthorized(props, <MenuContainer {...props} />)} />
                                <Route path="/airport/fleet" render={(props: any) => renderAuthorized(props, <FoxPerformanceContainer {...props} />)} />
                                <Route path="/airport/reviews" render={(props: any) => renderAuthorized(props, <ReviewsContainer {...props} />)} />
                                <Route path="/airport/team" render={(props: any) => renderAuthorized(props, <TeamContainer {...props} variant={AccountType.AIRPORT_ACCOUNT} />)} />
                                <Route path="/airport/demographics" render={(props: any) => renderAuthorized(props, <DemographicsContainer {...props} />)} />

                                <Route path="/merchant/onboarding" render={(props: any) => (
                                    <AppFrameContainer location={props.location} component={<OnboardingContainer variant={AccountType.MERCHANT_ACCOUNT} {...props} />} />
                                )} />
                                <Route path="/airport/onboarding" render={(props: any) => (
                                    <AppFrameContainer location={props.location} component={<OnboardingContainer variant={AccountType.AIRPORT_ACCOUNT} {...props} />} />
                                )} />
                                <Route path="/fox/onboarding" render={(props: any) => (
                                    <AppFrameContainer location={props.location} component={<OnboardingContainer variant={AccountType.FOX_ACCOUNT} {...props} />} />
                                )} />
                                <Route path="/:accountType/forgot" render={(props: any) => (
                                    <AppFrameContainer location={props.location} component={<RequestPasswordReset {...props} />} key={uuidv4()} />
                                )} />
                                <Route path="/fox/reset/:token" render={(props: any) => (
                                    <AppFrameContainer location={props.location} component={<PasswordReset {...props} />} key={uuidv4()} />
                                )} />
                                <Route path="/merchant/reset/:token" render={(props: any) => (
                                    <AppFrameContainer location={props.location} component={<PasswordReset {...props} />} key={uuidv4()} />
                                )} />

                                <Route exact path="/:iata?" render={(props: any) => (<AppFrameContainer location={props.location}  component={<Home {...props} />} key={uuidv4()} />)} />
                            </Switch>
                        </Suspense>
                    </BrowserRouter>
                </MuiThemeProvider>
            </Provider>
        </IntlProvider>
    )
}

ReactDOM.render(
    // strict mode will occasionally cause duplicate network requests , but this is only in development builds
    // https://github.com/axios/axios/issues/2825#issuecomment-883635938
    <React.StrictMode>
        <ReactNotifications />
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();


let updateChecker: number | null = null


// the initial update check when app first opens
store.dispatch(serviceWorkerInitialize())
registerServiceWorker({
    onSuccess: () => {
        store.dispatch(serviceWorkerSuccess())
    },
    onUpdate: (registration) => {
        store.dispatch(serviceWorkerHasUpdate(registration))

        // we have an update, clear the updateChecker interval
        if (updateChecker !== null) {
            // stop the update checker once we found an update.
            console.log("clearing updateChecker")
            clearInterval(updateChecker)
        }
    }
});
window.setTimeout(() => store.dispatch(serviceWorkerUpdateTimeout()), 10000)


// recurring update checks that run at some frequency in the background. checks every 6 hours
updateChecker = window.setInterval(() => {
    store.dispatch(serviceWorkerInitialize())
    window.setTimeout(async () => {
        store.dispatch(serviceWorkerUpdateTimeout())
        if ('serviceWorker' in navigator) {
            console.log("checking for updates...")
            const reg = await navigator.serviceWorker.ready
            reg.update()
        }
    }, 5000)
}, 21600000)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
