/* DEPRECATED. theme file shouldn't be in shared library */

import { createMuiTheme } from "@material-ui/core/styles"
import { red, blue } from "@material-ui/core/colors"

// reference:
// https://material-ui.com/customization/default-theme/

export const FetchyOrangeLight = "#FFB37D"
export const FetchyOrange = "#FF832D"
export const FetchyOrangeDark = "#E26005"

export const FetchyBlue = "#4871B8"

export const MaterialFoxTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiGrid: {
            item: {
                padding: "5px"
            }
        }
    },
    palette: {
        primary: {
            light: FetchyOrangeLight,
            main: FetchyOrange,
            dark: FetchyOrangeDark,
            contrastText: '#fff',
        },
        secondary: {
            light: "#6B90CE",
            main: "#4871B8",
            dark: "#2B5AA9",
            contrastText: "#fff",
        },
        text: {
            primary: "#27282c",
            secondary: "#949AA2"
        }
    }
})

export const FetchyColors = {
    primary: MaterialFoxTheme.palette.primary.main,
    secondary: MaterialFoxTheme.palette.secondary.main,
    lightText: MaterialFoxTheme.palette.text.secondary,
    darkText: MaterialFoxTheme.palette.text.primary
}

export const FetchyStyles = {
    criticalButton: {
        backgroundColor: red[600],
        color: "#fff",
        "&:hover": {
            backgroundColor: red[700],
        }
    },
    informationButton: {
        backgroundColor: blue[700],
        color: "#fff",
        "&:hover": {
            backgroundColor: blue[800],
        }
    },
    highlightText: {
        color: FetchyOrange,
    },
    subtleText: {
        color: "#949AA2"
    }
}



