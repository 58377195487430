import { initializeApp, FirebaseApp } from "firebase/app";
import {
    Messaging as FirebaseMessaging,
    getMessaging as firebaseGetMessaging
} from "firebase/messaging"
import {
    Auth as FirebaseAuth,
    getAuth as firebaseGetAuth
} from "firebase/auth"
import {
    Firestore as FirebaseFirestore,
    getFirestore as firebaseGetFirestore
} from "firebase/firestore"


// this firebase project is solely used to validate the credentials of airport accounts (and merchant accounts?). 
const config = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT}.firebaseapp.com`,
    databaseURL: `https://${process.env.REACT_APP_FIREBASE_PROJECT}.firebaseio.com`,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT,
    storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT}.appspot.com`,
}
export const firebaseApp: FirebaseApp = initializeApp(config, "auth");
export const airportAuth: FirebaseAuth = firebaseGetAuth(firebaseApp)


// this is the firebase project use to store all order data coming through the fetchyfox platform. it's used by the andoria backend, traveler app
// and this dashboard
const platformConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_PLATFORM_KEY,
    authDomain: `${process.env.REACT_APP_FIREBASE_PLATFORM_PROJECT}.firebaseapp.com`,
    databaseURL: `https://${process.env.REACT_APP_FIREBASE_PLATFORM_PROJECT}.firebaseio.com`,
    projectId: process.env.REACT_APP_FIREBASE_PLATFORM_PROJECT,
    storageBucket: `${process.env.REACT_APP_FIREBASE_PLATFORM_PROJECT}.appspot.com`,
    messagingSenderId: process.env.REACT_APP_FIREBASE_PLATFORM_MSG_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_PLATFORM_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_PLATFORM_MEASUREMENT_ID
}

export const firebasePlatformApp: FirebaseApp = initializeApp(platformConfig, "platform")
export const platformMessaging: FirebaseMessaging = firebaseGetMessaging(firebasePlatformApp);
export const platformAuth: FirebaseAuth = firebaseGetAuth(firebasePlatformApp)
export const platformVapidKey: string = process.env.REACT_APP_VAPID_KEY || "" // used to send push notifications
export const platformFirestore: FirebaseFirestore = firebaseGetFirestore(firebasePlatformApp)


export const registerPushNotificationSW = async () => {
    if ('serviceWorker' in navigator) {
        return window.navigator.serviceWorker
            .getRegistration('/firebase-push-notification-scope')
            .then((serviceWorker) => {
                if (serviceWorker) return serviceWorker;
                return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
                    scope: '/firebase-push-notification-scope',
                });
            });
    }
    throw new Error('The browser doesn`t support service worker.');
};
