import { AirportAction, AirportState, ReduxAction } from "../constants"

export function setAirport(airportId: string, iataCode: string, name: string,
    iso4217: string, country: string, countryCode: string, timezone: string, latitude: number,
    longitude: number): ReduxAction<AirportAction, AirportState> {
    const action = {
        type: AirportAction.SET_AIRPORT,
        props: {
            airportId, iataCode, name, iso4217,
            country, timezone, latitude, longitude,
            countryCode
        }

    }
    return action
}

export function clearAirport(): ReduxAction<AirportAction, AirportState> {
    const action = {
        type: AirportAction.CLEAR_AIRPORT,
        props: {}
    }
    return action
}