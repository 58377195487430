import { AppFrameAction, AppFrameState, ReduxAction } from "../constants"

export default (prevState: AppFrameState = {}, action: ReduxAction<AppFrameAction, AppFrameState>): AppFrameState => {
    switch (action.type) {
        case AppFrameAction.SET_UPDATE_SNACKBAR: {
            const { updateAvailable } = action.props
            return {
                ...prevState,
                updateAvailable
            }
        }

        case AppFrameAction.SET_STATUS_SNACKBAR: {
            const { variant, value } = action.props.statusSnackMessage
            return {
                ...prevState,
                statusSnackMessage: { variant, value }
            }
        }

        case AppFrameAction.HIDE_STATUS_SNACKBAR: {
            return {
                ...prevState,
                statusSnackMessage: {
                    variant: null,
                    value: null
                }
            }
        }

        case AppFrameAction.SET_CONFIRM_DIALOG: {
            const { value, title, onClickCancel, onClickConfirm } = action.props.confirmDialogMessage
            return {
                ...prevState,
                confirmDialogMessage: {
                    value,
                    title,
                    onClickConfirm,
                    onClickCancel
                }
            }
        }

        case AppFrameAction.SET_BGPROC_SNACKBAR: {
            const { bgSnackMessage } = action.props
            return {
                ...prevState,
                bgSnackMessage
            }
        }

        case AppFrameAction.SET_BGPROC_DIALOG: {
            const { bgDialogMessage } = action.props
            return {
                ...prevState,
                bgDialogMessage
            }
        }

        case AppFrameAction.HIDE_BGPROC_DIALOG: {
            return {
                ...prevState,
                bgDialogMessage: null,
            }
        }

        case AppFrameAction.HIDE_BGPROC_SNACKBAR: {
            return {
                ...prevState,
                bgSnackMessage: null
            }
        }

        default: {
            return prevState
        }
    }
}
