import { ServiceWorkerAction, ServiceWorkerState, ReduxAction } from "../constants"


export default (prevState: ServiceWorkerState = {},
    action: ReduxAction<ServiceWorkerAction, ServiceWorkerState>): ServiceWorkerState => {
    
    switch (action.type) {
        case ServiceWorkerAction.SW_REGISTER: {
            return {
                checking: true,
                initialized: false,
                updated: false,
                registration: null
            }
        }
        case ServiceWorkerAction.SW_INIT: {
            return {
                checking: false,
                initialized: true,
                updated: false,
                registration: null
            }
        }
        case ServiceWorkerAction.SW_UPDATE: {
            const { registration } = action.props
            return {
                checking: false,
                initialized: false,
                updated: true,
                registration
            }
        }
        case ServiceWorkerAction.SW_UPDATE_TIMEOUT: {
            return {
                ...prevState,
                checking: false
            }
        }        
        default:
            return prevState
    }
}
