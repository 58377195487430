import { combineReducers } from "redux"

import credentials from "./credentials"
import airport from "./airport"
import appframe from "./appframe"
import serviceworker from "./serviceworker"
import session from "./session"
import { permissionReducer, notificationReducer } from "./pushnotis"

export default combineReducers({
    credentials,
    appframe,
    airport,
    serviceworker,
    session,
    permissionReducer,
    notificationReducer
})

