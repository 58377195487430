import {
    PushNotificationState,
    PushNotificationAction,
    PushNotificationPermActions,
    PushNotificationStateTransition,
    ReduxAction
} from "../constants"


export const permissionReducer = (
    prevState: PushNotificationState = {},
    action: ReduxAction<PushNotificationPermActions, PushNotificationStateTransition>): PushNotificationState => {

    switch (action.type) {
        case PushNotificationPermActions.SET_ENABLED: {
            const { enabled } = action.props
            return {
                ...prevState,
                enabled
            }
        }
        default:
            return prevState
    }
}

export const notificationReducer = (
    prevState: PushNotificationState = {},
    action: ReduxAction<PushNotificationAction, PushNotificationStateTransition>): PushNotificationState => {

    switch (action.type) {
        case PushNotificationAction.PUSH_NOTIS_DATA: {
            const { notification } = action.props
            if (notification) {

                const maxLength = prevState.maxQueueLength || 10
                let notifications = prevState.notifications || []

                if (notifications.length >= maxLength) {
                    notifications = notifications.slice(-1 * (maxLength - 1))
                }
                notifications.push(notification)

                return {
                    ...prevState,
                    notifications
                }
            }
            else {
                return prevState
            }
        }
        case PushNotificationAction.POP_NOTIS_DATA: {
            const { notificationId } = action.props
            let notifications = prevState.notifications || []
            if (notificationId && notifications.length > 0) {
                notifications = notifications.filter(notis => notis.id !== notificationId)
            }
            else if(notifications.length > 0) {
                notifications.shift()
            }
            else {
                notifications = []
            }

            return {
                ...prevState,
                notifications
            }
        }
        case PushNotificationAction.CLEAR_NOTIS_DATA: {
            return {
                ...prevState,
                notifications: []
            }
        }
        case PushNotificationAction.SET_MAX_QUEUE_LENGTH: {
            let maxQueueLength  = action.props.maxQueueLength || 10
            maxQueueLength = maxQueueLength > 1 ? maxQueueLength : 10
            let notifications = prevState.notifications || []


            if(notifications.length > maxQueueLength ) {
                notifications = notifications.slice(-1 * maxQueueLength)
                return {
                    ...prevState,
                    notifications,
                    maxQueueLength
                }
            }
            else {
                return {
                    ...prevState,
                    maxQueueLength
                }
            }
        }
        default:
            return prevState
    }
}