import { SessionAction, SessionState, ReduxAction } from "../constants"

export function initSession(accountType: string, drawerMenu: Array<any>): ReduxAction<SessionAction, SessionState> {

    const action: ReduxAction<SessionAction, SessionState> = {
        type: SessionAction.INIT_SESSION,
        props: {
            accountType, drawerMenu
        }
    }

    return action
}

export function endSession(): ReduxAction<SessionAction, SessionState> {
    const action: ReduxAction<SessionAction, SessionState> = {
        type: SessionAction.END_SESSION,
        props: {}
    }

    return action
}

export function setPlaces(places: Array<any>): ReduxAction<SessionAction, SessionState> {
    const action: ReduxAction<SessionAction, SessionState> = {
        type: SessionAction.SET_PLACES,
        props: {
            places
        }
    }

    return action
}

export function setProfile(firstName: string, email: string, lastName = ""): ReduxAction<SessionAction, SessionState> {
    const action: ReduxAction<SessionAction, SessionState> = {
        type: SessionAction.SET_PROFILE,
        props: {
            firstName, lastName, email
        }
    }

    return action
}

export function clearPlaces(): ReduxAction<SessionAction, SessionState> {
    const action: ReduxAction<SessionAction, SessionState> = {
        type: SessionAction.CLEAR_PLACES,
        props: {
            places: []
        }
    }

    return action
}

export function setActivePermissions(placeId: string, teamId: string, permissions: string): ReduxAction<SessionAction, SessionState> {
    const action: ReduxAction<SessionAction, SessionState> = {
        type: SessionAction.SET_ACTIVE_PERMISSIONS,
        props: {
            placeId, teamId, permissions
        }
    }

    return action
}

export function clearActivePermissions(): ReduxAction<SessionAction, SessionState> {
    const action: ReduxAction<SessionAction, SessionState> = {
        type: SessionAction.CLEAR_ACTIVE_PERMISSIONS,
        props: {}
    }

    return action
}

export function setActivePlace(placeId: string): ReduxAction<SessionAction, SessionState> {
    const action: ReduxAction<SessionAction, SessionState> = {
        type: SessionAction.SET_ACTIVE_PLACE,
        props: { placeId }
    }
    return action
}