import { CredentialAction, CredentialState, ReduxAction } from "../constants"


export default (state: CredentialState = {}, action: ReduxAction<CredentialAction, CredentialState>): CredentialState => {
    switch(action.type) {
        case CredentialAction.SET_CREDENTIALS: {
            const { username, password } = action.props
            return {
                ...state,
                username,
                password,

            }
        }
        case CredentialAction.CLEAR_CREDENTIALS: {
            return {
                email: null,
                username: null,
                password: null
            }
        }
        case CredentialAction.SET_EMAIL: {
            const { email } = action.props
            return {
                ...state,
                email
            }
        }
        default:
            return state
    }
}
